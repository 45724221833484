import { SchoolService, BaseService } from "@/services/school.service";

const PupilService = {
    getAll () {
        return SchoolService.getAll("pupils")
    },

    add(payload) {
    	return SchoolService.create("pupils", payload)
    },

    update(result) {
    	return SchoolService.update("pupils", result.identifier, result)
    },

    delete(result) {
    	return SchoolService.delete("pupils", result.identifier, null)
    },

    getClasses() {
        return SchoolService.getAll("classes")
    },

    getTimeline(result, calendarYear, after, before) {
        return SchoolService.getAll("pupils/" + result.identifier + "/timeline?stored=" + calendarYear + "&entry_after=" + after + "&entry_before=" + before)
    }
     
}

export { PupilService };
