<template>
  <div class="pupils">
<header class="inner-head">
      <div class="flex justify-between ">
        <h2 class="inner-head-title">Pupils</h2>

        <ul class="pt-1">
                 <li>
          <button
            type="button"
            class="action-button hover:opacity-75"
            @click="addNew()"
          >  
          <i class="fas fa-plus"></i>
           Add New</button>
        </li>

        <li v-if="1 == 2">
          <button
            type="button"
            class="action-button blue medium icon fa-upload"
            @click="selectedType = 'warm-up'"
          >
            Upload Data
          </button>
        </li>
        </ul>
      </div>
    </header>

    <div class="filter-bar">
        <ul class="actions">
           <li class="opacity-75">Search:</li>
        <li>
          <input
            type="search"
            class="          filter-bar-search focus:outline-none"
            name="search"
            v-model="search"
            placeholder="Enter search term..."
          />
        </li>
        
         <li class="opacity-75">Filter by:</li>
        <li>
          <button
            type="button"
            class="filter-button focus:outline-none hover:opacity-75"
            title="Filter"
            @click="setFilter()"
          >
          <i class="fas fa-user-friends "                  
          v-bind:class="showFilter ? 'bg-action-blue text-white' : 'bg-gray-400'"></i> Pupils
          </button>
        </li>
      </ul>
    </div>

 <filterSection
      class=""
      :classes="classesArray"
      :fullView="true"
      v-if="showFilter"
      @classUpdated="updateFilteredClass"
      @yearsUpdated="updateFilteredYears"
      @typesUpdated="updateFilteredTypes"
    >
    </filterSection>


 

      <p class="m-6 pt-2 text-gray-500">
        {{ filteredList.length }} pupils in view
      </p>
      <p v-if="showMessage" class="save-notice">
        {{ msg }} <i class="far fa-check-circle"></i>
      </p>


   

    <table
      class="
        w-95p
        mx-auto
        mb-2
        h-12
        pt-2
        align-text-bottom
        bg-white
        rounded
        text-left text-gray-400
      "
    >
      <thead>
        <tr>
          <th
            width="5%"
            class="text-xl pl-3 cursor-pointer"
            @click="setSelectAll()"
          >
            <i
              class="far"
              v-bind:class="[
                selectAll
                  ? 'fa-check-circle text-orange-300'
                  : 'fa-circle text-gray-200',
              ]"
              style="display: none"
            ></i>
          </th>
          <th width="10%">Year Group</th>
          <th width="30%" class="pl-2">Name</th>
          <th width="19%">Class</th>
          <th width="5%" align="center">PP</th>
          <th width="15%" class="pl-3">SEN</th>
          <th width="5%" align="center">EAL</th>
          <th width="10%"></th>
        </tr>
      </thead>
    </table>

    <div class="table-wrapper">
      <div v-if="errored" class="empty">
        <p>
          We are not able to retrieve this information at the moment, please try
          back later
        </p>
      </div>

      <div id="content-area" v-else>
                 <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
         <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Pupils</p>
      </div>

        <div v-else>
          <table
            v-if="componentLoaded"
            class="table-wrapper w-95p bg-white mx-auto rounded shadow-sm"
          >
            <tbody>
              <tr v-for="pupil in filteredList" :key="pupil.identifier">
                <td
                  width="5%"
                  class="text-lg cursor-pointer"
                  @click="selectPupil(pupil)"
                >
                  <i
                    v-bind:class="[
                      isSelected(pupil)
                        ? 'fas fa-check-circle text-orange-300'
                        : ' far fa-circle text-gray-200',
                    ]"
                    style="display: none"
                  ></i>
                </td>
                <td width="10%">
                  {{pupilYearGroupDisplay(pupil)}}
                </td>
                <td width="30%">{{ pupil.name }}</td>
                <td width="20%">{{ pupil.class }}</td>
                <td width="5%">
                  <i
                    class="icon list"
                    v-bind:class="setOnClass(pupil.premium_pupil.Int64)"
                  />
                </td>
                <td width="15%">{{ pupil.sen_status.String }}</td>
                <td width="5%">
                  <i
                    class="icon list"
                    v-bind:class="setOnClass(pupil.english_second.Int64)"
                  />
                </td>
                <td
                  width="10%"
                  class="text-center text-xs cursor-pointer text-blue-400"
                >
                  <a @click="editPupil(pupil)">View</a>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="m-5 mx-12" v-else>
            We could not find any data for your given selection.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { yearList } from "../assets/js/utils";
import { pupilMixin } from "@/mixins/pupilMixin.js";

const filterSection = () => import("@/components/helpers/PupilFilter.vue");

export default {
  name: "pupils",
  mixins: [pupilMixin],
  props: {
    msg: { type: String, required: false },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return this.filteredList.length > 0;
    },
    showMessage() {
      return this.msg != undefined && this.msg.length > 0;
    },
    orderedPupils() {
      var vm = this;
      let years = vm.filteredYears;
      let types = vm.filteredTypes;
      var classChosen = vm.filteredClass;

      return this.filterPupils(years, types, classChosen);
    },
    filteredList() {
      return this.orderedPupils.filter((item) => {
        var vm = this;
        return item.name.toLowerCase().includes(vm.search.toLowerCase());
      });
    },
  },
  data() {
    return {
      showFilter: false,
      selectedPupils: [],
      filteredYears: [],
      filteredTypes: [],
      search: "",
      filteredClass: {},
      selectAll: false,
    };
  },
  created() {},
  mounted() {

  },
  methods: {
    selectPupil(pupil) {
      if (this.selectedPupils.some((p) => p.identifier === pupil.identifier)) {
        this.selectedPupils.splice(
          this.selectedPupils.findIndex(
            (item) => item.identifier === pupil.identifier
          ),
          1
        );
      } else {
        this.selectedPupils.push(pupil);
      }
    },
    isSelected(pupil) {
      if (this.selectedPupils.some((p) => p.identifier === pupil.identifier)) {
        return true;
      } else {
        return false;
      }
    },
    setSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll == false) {
        this.selectedPupils = [];
      } else {
        this.selectedPupils = [];
        this.selectedPupils = this.selectedPupils.concat(this.filteredList);
      }
    },
    addNew() {
      let pupil = {};
      this.$router.push({
        name: "PupilEdit",
        params: { pupil: pupil, newAddition: true, classes: this.classesArray, years: this.years },
      });
      window.scrollTo(0, 0);
    },
    editPupil(pupil) {
      this.$router.push({
        name: "PupilEdit",
        params: {
          pupil: pupil,
          newAddition: false,
          classes: this.classesArray,
          years: this.years 
        },
      });
      window.scrollTo(0, 0);
    },
    setOnClass(value) {
      if (value == 1) {
        return "fas fa-check-circle text-green-400";
      } else {
        return "fas fa-times-circle text-gray-300";
      }
    },
    setFilter() {
      this.showFilter = !this.showFilter;
      if (this.showFilter == false) {
        this.filteredTypes = [];
        this.filteredYears = [];
        this.filteredClass = this.classesArray[0];
      }
    },
    updateFilteredClass(value) {
      this.filteredClass = value;
    },
    updateFilteredYears(values) {
      this.filteredYears = values;
    },
    updateFilteredTypes(values) {
      this.filteredTypes = values;
    },
  },
  components: {
    filterSection,
  },
};
</script>

<style>
i.icon {
  font-size: 18px;
  padding-left: 4px;
  padding-top: 4px;
}
</style>
