import { PupilService } from "@/services/pupil.service"
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";
import { mapGetters } from "vuex";
export const pupilMixin = {
    mixins: [yearGroupMixin],
    data() {
        return {
            pupilArray: [],
            classesArray: [],
            loading: true,
            errored: false,
        };
    },
    created() {
        this.fetchClasses();
        this.fetchData();
    },
    computed: {
        ...mapGetters({

        }),
        pupilArrayCount() {
            return this.pupilArray.length
        }
    },
    methods: {
        fetchData() {
            PupilService.getAll()
                .then(response => this.buildPupilArray(response.data))
                .catch(err => {
                    console.log("Pupils " + err);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        },
        buildPupilArray(data) {
            this.pupilArray = data;
        },
        fetchClasses() {
            PupilService.getClasses()
                .then(response => this.buildClassList(response.data))
                .catch(err => {
                    console.log("classes " + err);
                    this.errored = true;
                })
        },
        buildClassList(data) {
            this.classesArray = data.map(function (item, index) {
                return { "id": index + 1, "name": item }
            });
            // this.classesArray = [{ id: 0, name: "Showing All Classes" }].concat(this.classesArray)
        },
        pupilYearGroupDisplay(pupil) {
            let found = this.getYearGroupFromValue(pupil.yeargroup) 
            return found != undefined ? found.name : "Unknown"
        },
        filterPupils(years, types, classChosen) {
            if (years.length == 0 && types.length == 0 && classChosen.id == 0) {
                return this.$orderBy(this.pupilArray, ["yeargroup", "class", "name"]);
            } else {
                var filtered = this.pupilArray.filter(function (pupil) {
                    let filtered = true;

                    if (years.length > 0) {
                        filtered = years.includes(pupil.yeargroup);
                    }

                    if (filtered) {
                        if (classChosen.id > 0) {
                            filtered = pupil.class === classChosen.name;
                        }
                    }

                    if (filtered) {
                        if (types.length > 0) {
                            if (types.includes("male")) {
                                filtered = pupil.gender.toLowerCase() == "male";
                            } else if (types.includes("female")) {
                                filtered = pupil.gender.toLowerCase() == "female";
                            } else if (types.includes("premium")) {
                                filtered = pupil.premium_pupil.Int64 > 0;
                            } else if (types.includes("eal")) {
                                filtered = pupil.english_second.Int64 > 0;
                            } else if (types.includes("sen")) {
                                filtered =
                                    pupil.sen_status.String.toLowerCase().startsWith("sen");
                            }
                        }
                    }

                    return filtered;
                });

                return this.$orderBy(filtered, ["yeargroup", "class", "name"]);
            }
        }

    }
};
